// main layout

.fixed-sn {
    .double-nav,
    main,
    footer {
      padding-left: 0;
    }
  }
  
  .fixed-sn {
    main,
    footer {
      margin-left: 0;
      margin-right: 0;
      // margin-left: 3.75rem;
      // margin-right: 3.75rem;
    }
  
    main {
      padding-top: 64px; //should be equal to header navbar height
    }
  }
  
  .cta-wrapper {
    width: 3.75rem;
    height: calc(100vh - 65px);
    position: fixed;
    right: 0;
    top: 65px;
    // border-left: 1px solid $grey-lighten-3;
  }
  
  // layout collapse/expand
  .layoutExpand {
    .double-nav,
    main,
    footer {
      padding-left: 15rem;
      // padding-left: calc(15rem - 3.75rem); //collapse menu width 3.75rem
    }
  }
  
  .layoutCollapse {
    .double-nav,
    main,
    footer {
      padding-left: 3.75rem;
    }
  }
  
  // login page
  .login-content {
    max-width: 28.75rem;
    margin-left: 5vw;
  }
  
  .action-item {
    cursor: pointer;
  
    .md-accordion & {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 90;
  
      i {
        &:hover {
          color: $primary;
        }
      }
    }
  
    .card-header & {
      position: absolute;
      top: 18px;
      right: 10px;
    }
  }
  
  // sticky content
  
  .sticky-wrapper {
    padding-left: 15px;
    width: 400px;
    position: relative;
  }
  
  .sticky-content {
    // height: calc(100vh - 265px);
    height: 100%;
    overflow-y: auto;
  
    &.sticky {
      position: sticky;
      top: 80px;
    }
  
    &-50vh {
      max-height: 400px;
    }
  }
  
  .header {
    &.position-sticky {
      top: 0;
      z-index: 100;
      background-color: $white;
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
  
  // @include media-breakpoint-up(lg) {
  
  //     .aside-content,
  //     .sticky-content{
  //         width: 300px !important;
  //     }
  //  }
  // @include media-breakpoint-up(xl) {
  //     .aside-content,
  //     .sticky-content{
  //         width: 330px !important;
  //     }
  //  }
  
  // Medium devices (tablets, 768px and up)
  // @include media-breakpoint-down(sm) {
  //   // layout collapse/expand
  //   .layoutExpand,
  //   .layoutCollapse {
  //     .double-nav,
  //     main,
  //     footer {
  //       padding-left: 3.75em;
  //     }
  //   }
  // }
  
  .login-bg {
    background-color: rgba($secondary, 0.2);
  }
  