//table collapse detail

.table-detail {
  background-color: lighten($title-color, 75%);
  border: 1px solid $grey-lighten-2;
  padding: 0 1rem 0.5rem;
  cursor: default;
  border-radius: 0 0 4px 4px;
}

.table-card {
  padding: 0;
  margin-bottom: 0.5rem;
  background-color: white;
  // min-height: 200px;
  // border: 1px solid $grey-lighten-2;
  border-radius: 2px;

  li {
    display: flex;
    margin-bottom: 2px;

    // justify-content: space-between;
    label {
      width: 62%;
      margin-right: 1rem;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }

  .value-text {
    margin-bottom: 0;
    // width: 40%;
    font-weight: 500;
    font-size: 0.8rem;
  }
}

.user-update {
  // border-top: 1px solid darken($grey-lighten-3, 10%);
  padding-top: 0.5rem;

  label {
    margin-right: 0.85rem;
  }
}

.date-section {
  border-top: 1px solid $grey-lighten-3;
  padding-top: 0.5rem;
}

@media (min-width: 1600px) {
  .padding-xl {
    padding: 0 10%;
  }
}

// mdb-date-picker .md-form .form-control + .datepicker-inline-icon {
// top: 3px !important;
// padding: 0 !important;
// cursor: pointer;
// }

// mdb calendar custom css
// .picker__button--today {
// background: $secondary !important;
// color: white;
// }

// .picker__box .picker__footer .picker__button--today:before
// {
// top: -.25em;
// width:0;
// border-top:0 !important;
// content: "\f00c" !important;
// font-family: "Font Awesome 6 Free";
// padding-right: 10px !important;
// }
// .picker__box .picker__footer .picker__button--clear:before{
// top: -.25em;
// width:0;
// border-top:0 !important;

// }
// .picker__button--clear{
// background: $blue-grey-lighten-5 !important;
// }
// !--end mdb calendar custom css
.modal-form-title {
  font-weight: 500;
  color: $title-color;
}

.project-detail {
  &-label {
    // min-width: 60px;
    margin-right: 0.5rem;
    font-size: 14px;
  }

  &-name {
    font-weight: 400;
    font-size: 14px;
  }

  &-card {
    padding: 0.25rem;
    border-radius: 4px;
  }

  &-title {
    font-weight: 500;
    color: $title-color;
    // padding-left: 1rem;
    font-size: 13.8px;
  }
}

.tab-content {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}

.list-item-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0;
  margin-bottom: 0.25rem;

  li {
    display: flex;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    width: 33%;
    align-items: center;

    label {
      margin-right: 1rem;
      margin-bottom: 0;
    }

    p {
      font-weight: 500;
    }
  }
}

.label-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.label-55 {
  width: 55%;
}

.label-34 {
  width: 34%;
}

.list-items {
  padding: 0.5rem;
  margin-bottom: 0.25rem;

  .btn-sm {
    padding: 0.25rem !important;
  }

  li {
    display: flex;
    font-weight: 400;
    margin-bottom: 0.5rem;

    label {
      width: 54%;
      font-size: 0.8rem;
    }

    p {
      font-weight: 500;
      font-size: 0.8rem;
      text-align: right;
      width: 51%;
    }
  }
}

.dates-list {
  width: 33%;
}

.dates-list-sm {
  width: 20%;
}

.item-center {
  padding-top: 12%;

  .card {
    min-width: 400px;
  }

  color: $body-text-color;
}

.cal-label {
  color: $body-text-color;
  margin-right: 1rem;
  font-size: 13px;
  font-weight: 500;
}

.tr-light-green {
  td {
    background-color: $light-green;
  }
}
.tr-yellow {
  td {
    // background-color: #efefef;
    background-color: #fffbe1;
  }
}
.tr-red {
  td {
    background-color: #ffcece;
    // &:first-child{
    //   border-left: 4px solid $danger;
    // }
  }
}
.tr-blue {
  td {
    background-color: #bdd6ef;
    // &:first-child{
    //   border-left: 4px solid $danger;
    // }
  }
}

.table-sweep {
  .tr-yellow:last-child td {
    border-bottom: 2px solid $title-color;
  }
}

.report-cal {
  padding-left: 0.5rem;
  width: 210px;

  input {
    width: 70px;
    margin-left: 0.5rem;
  }
}

.portfolio_list {
  width: 250px;
}

.dashboard-card {
  cursor: pointer;
}

.bold-mid {
  font-weight: 500;
}

.icon-md {
  font-size: 1rem;
  margin-right: 0.25rem;
}

.breadcrumb-body {
  background-color: transparent;

  li {
    color: $link;
  }

  .breadcrumb-item + .breadcrumb-item:first-child::before {
    content: none !important;
  }
}

.breadcrumb-sm li {
  font-size: 0.9rem;
}

// file upload modal mdb
.file-list {
  padding-left: 0;
  // width: 20vw;
  margin-left: 1rem;
  margin-bottom: 0.25rem;

  // @include transition-main(.3s);
  li {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 0.25rem 0 0;
    // border-bottom: 1px solid $input-border-color;
    font-size: 0.85rem;
    color: $body-text-color;
    margin: 0.5rem 0;

    input {
      background-color: white !important;
      margin-bottom: 0;
      font-size: 0.785rem;
      padding-left: 0;
      padding-bottom: 0;

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.btn-unlink {
  font-size: 0.8rem;
  color: $body-text-color;

  &:hover {
    color: red;
  }
}

@media (max-width: 600px) {
  .mob-hide {
    display: none !important;
  }

  .mob-login {
    .w-75 {
      width: 100% !important;
    }
  }
}

.w-80 {
  width: 80% !important;
}

.custom-file-label {
  height: 55px;
  padding: 1rem 0.75rem;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;

  &:hover,
  &:focus {
    background-color: lighten($primary, 60%) !important;
  }

  &:after {
    content: none !important;
  }
}

.custom-file-input {
  height: 50px;
  cursor: pointer;
}

.btn-filepicker {
  // height: 50px;
  width: 100%;
  font-size: 0.9rem !important;
  font-weight: 400;
  background-color: lighten($primary, 50%);
  padding: 1rem !important;

  &:hover {
    background-color: lighten($primary, 40%);
  }
}
// document library upload btn dropdowns
.doc-file-label {
  height: auto;
  padding: 8px;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 400;
  background-color: white;
  border: 0;
  cursor: pointer;
  &:hover {
    background-color: $grey-lighten-3;
    color: $primary;
  }
}
// folder tree move folder

.folder-tree {
  list-style: none;
  color: $body-text-color;
  margin: 0.25rem 1rem 0 0;

  .list-item {
    font-weight: 400;
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 0;
    font-size: 0.85rem;

    &:hover {
      background-color: lighten($primary, 48%);
      color: $primary;

      .fas,
      .far {
        color: $link;
      }
    }
  }

  .selected {
    color: $primary;
    font-weight: 500;

    &:after {
      font-family: "Font Awesome 6 Free";
      content: "\f058";
    }
  }

  .file-inside {
    padding-left: 16px;
    list-style: none;
    margin-top: 8px;
  }

  .btn-sm {
    // padding: 0.25rem 0.25rem !important;
    .far,
    .fas {
      font-size: 1rem !important;
      color: $body-text-color;

      &:focus,
      &:hover {
        color: $primary;
      }
    }
  }
}
.link-doc-modal {
  .folder-tree {
    .selected {
      &:after {
        content: none;
      }
    }
  }
}

.upload-file {
  flex-grow: 1;
}

.value-text {
  font-weight: 500;
  margin-bottom: 0;
  color: $title-color;
}

// tooltip-documents library
// .tooltip-lg{
// .tooltip-inner{
// text-align: left !importaformnt;
// min-width: 100px !important;
// max-width: 100% !important;
// height: auto;
// }

// }

.li-flex {
  label {
    display: flex;
    width: 70% !important;
    justify-content: space-between;
  }
}

.see-more {
  white-space: unset;
  overflow: visible;
  text-overflow: unset;
}

.see-less-height {
  max-height: 40px;
}

.see-less-list {
  @extend .see-less-height;
  overflow: hidden;
}

.pdf-header {
  display: none;
  font-size: large;
  font-weight: 500;
  text-align: center;
  padding: 10px 0px;
}

.bg-expect {
  background-color: #f3f7fb;
}

// .text-xs {
//   font-size: 12.8px !important;
// }

// sweepdate box in project add modal

.sweepdate-box {
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;
  // background-color: lighten($primary, 50%);
  align-items: center;
  margin-top: 0.5rem;
  min-height: 40px;
  max-height: 72px;
  // resize: vertical;
  overflow: auto;
  border: 1px solid $input-border-color;
}

.datepicker input {
  display: block !important;
  border: 0;
  height: 0;
  padding: 0;
  margin: 0;
}

.bs-datepicker-container {
  padding: 0 !important;
}
.bs-datepicker-body {
  padding: 5px !important;
  height: 30px !important;
}
.bs-datepicker-head {
  height: 40px !important;
  padding: 6px !important;
}

// dashboard dropdown for more
.dashboard-dropdown-menu {
  // left: -40px !important;
  font-size: 13.8px;
  border: 0;

  button:hover {
    background-color: $border-blue;
    color: $primary;
  }
  button:focus {
    background-color: $border-blue !important;
    color: $primary;
  }
}

.over-ride-icon {
  display: flex;
  align-items: center;
  i {
    margin-right: 3px;
  }
}
.address-edit {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0rem;
  label {
    font-size: 12px;
  }
  li {
    display: flex;
    margin: 0.25rem;
    justify-content: space-between;
  }
}
.non-edit {
  background-color: #e2e2e2;
  width: 200px;
  padding: 0 0.25rem;
}

.item-amo-disabled {
  background-color: $input-disabled;
  // width: 200px;
  padding: 0 0.25rem;
}

.item-asm-editable {
  background-color: $blue-light;
}
.lock-sm {
  opacity: 0.7;
  font-size: 8px;
}
.edit-field .mydp-date {
  width: 75% !important;
}
.tr-border_light {
  tr {
    border-bottom: 1px solid white;
  }
}

// disbursement document sets

.doc-cards {
  display: flex;
  // justify-content: space-between;
  &-list {
    margin: 0 0.5rem;
    min-width: 33%;
    border: 1px solid $input-border-color;
    border-radius: 2px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.08);
    &:last-child {
      margin-left: 0;
    }
  }
  &-header {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 0.5rem;
    font-size: 13.8px;
    font-weight: 500;
  }
  &-body {
    font-size: 13px;
    padding: 0rem;
    list-style: none;
    li {
      border-bottom: 1px solid $input-border-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      &:last-child {
        border-bottom: 0;
      }
      p {
        width: 70%;
        margin-bottom: 0;
        margin-right: 0.5rem;
      }
    }
  }
  // .list-checked {
  //   border: 1px solid $secondary;
  // } commented for now
}
// disbursementcard
.draw-card {
  background-color: #f5f9f5;
  min-height: 260px;
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $input-border-color;
    padding: 2px 12px 0 !important;
    font-size: 12.8px;
  }
  &-body {
    padding: 12px !important;
    font-size: 12.8px;
    .file-list {
      margin-left: 0;
      list-style-type: decimal;
      height: 201px;
      overflow-y: auto;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.settings-card {
  @extend .draw-card;
  min-height: fit-content;
}
// toast override
.md-toast-info {
  background-color: $secondary !important;
}
.icon-a {
  color: #888;
}
.icon-fill {
  color: yellow;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: orange;
}

.accordion .card {
  .card-header {
    color: #555 !important;
    padding-top: 7x !important;
    padding-left: 5px !important;
  }
}

.accordion .card-body {
  padding: 0 !important;
}
.absolute-right {
  position: absolute;
  right: 0;
  margin-right: 3rem;
}
.flex-changes {
  flex-wrap: wrap;
  .form-group {
    width: 48%;
    margin-right: 10px;
  }
}

.border-top-down {
  border-left: 0 !important;
  border-right: 0 !important;
  background-color: #e6e6e6 !important;
}

mdb-select-dropdown > div .options {
  position: relative;
  overflow-y: auto;
  width: inherit;
}

mdb-select-dropdown .dropdown-content {
  max-width: 100px !important;
}

.multi-range-field {
  margin: 0 8px !important;
  padding: 8px 0 !important;
}

.popover.popover-bottom:before,
.popover.bs-tether-element-attached-top:before {
  display: none !important;
}

.popover.popover-bottom:after,
.popover.bs-tether-element-attached-top:after {
  display: none !important;
}

.popover.popover-bottom {
  margin-top: 0px !important;
  margin-left: -16px !important;
  margin-right: 13px !important;
}

app-advanced-search-filter div mdb-select mdb-select-dropdown div .options {
  width: 100% !important;
}

app-advanced-search-filter div div mdb-select div div.multiple {
  height: 32px !important;
  div.option {
    position: absolute;
    top: 0;
  }
}

app-advanced-search-filter {
  div div.input-group-prepend,
  div div.input-group-append mdb-select div div.single,
  div.input-group mdb-select div div.single {
    height: 32px !important;
  }
  div.input-group {
    height: 34px !important;
  }
}

.dashboard-header {
  height: auto;
  position: sticky;
  top: 0;
  z-index: 1;
}

// PEQ Cashflow dropdown input width
div .datepicker mdb-select div[role="listbox"] {
  width: 216px !important;
}

// .form-check-input[type='checkbox'] + label,
// label.btn input[type='checkbox'] + label {
//   padding-left: 24px !important;
// }

.form-switch .form-check-input[type="checkbox"]:checked {
  background-color: $secondary;
}
.form-switch .form-check-input:checked[type="checkbox"]:after {
  background-color: $secondary;
}

.form-check-input:checked:before {
  opacity: 0 !important;
}

.form-check-input:focus:before {
  opacity: 0 !important;
}

.projectsummary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #333f4f;
  padding-right: 10px;
  padding-left: 10px;
}
.projectsummary-header h4 {
  color: "white";
}

.projectsummary_header_img {
  padding: 4px;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

#lblCartCount {
  font-size: 12px;
  background: #79bb79;
  color: #fff;
  padding: 2px 5px;
  vertical-align: top;
  margin-left: -6px;
}

button[permission="LENDER_DATABASE-W"]span.badge.badge-warning {
  position: relative;
  top: -6px !important;
}

// dropdown for asset detail phase section
span > mdb-select[id="subPhaseList"] > mdb-select-dropdown > div .options {
  position: relative;
  overflow-y: auto;
  width: auto;
}

.asset-management-link {
  text-decoration: underline;
  font-weight: bold;
  color: #3571ba;
  position: absolute;
  top: 42px;
  left: 20px;
  font-size: 12px;
}
.asset-management-link:hover {
  cursor: pointer;
}

.custom-loader {
  background-color: white;
}

// datepicker
.datepicker-toggle-button {
  right: -4px !important;
}

.pricingCalculate {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
}

.pricingCalculate i {
  margin-right: 10px;
  color: #e3a807;
}

.share-modal .btn-trans {
  color: #333;
  padding: 0.25rem 0.5rem;
  box-shadow: none;
  border-radius: 2px !important;
  border: 1px solid #666;
}

.icon-color {
  color: #e3a807;
}

.selected-assessment:checked {
  background-color: $secondary-color-dark;
  color: $secondary-color-dark;
}

.selected-assessment[type="checkbox"]:checked {
  background-color: $secondary-color-dark;
}

.selected-assessment-container {
  margin-top: 20px;
}

.assessment-item {
  margin-bottom: 10px;
}

.assessment-label {
  margin-left: 5px;
}

.lock-treasury {
  width: 32px;
}

.creditmetrics-checkbox-label {
  margin-left: 10px;
}

.business-case-text {
  font-size: 0.8rem !important;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1201 !important;
}

#layout-list > li {
  visibility: unset;
}

// popover
mdb-popover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover {
  width: 132px;
  padding: 1rem 0.5rem;
  height: 50px;

  &-body {
    padding: 0.5rem 0.5rem;
  }
}

// Column filter
app-advance-column-filter div.input-group div mdb-select input.select-input {
  min-height: 2rem !important;
  height: 2rem !important;
}

app-advance-column-filter div.input-group mdb-select input.select-input {
  min-height: 2rem !important;
  height: 2rem !important;
}

app-advance-column-filter div.input-group div span.input-group-text {
  min-height: 2rem !important;
  height: 2rem !important;
}

app-advance-column-filter div.input-group div input#range_x_y {
  min-height: 2rem !important;
  height: 2rem !important;
}

.scopeOfWorkTable {
  height: 700px;
  overflow-y: auto;
}

// dashboard filter
.form-group.datepicker input.datepicker {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tox-statusbar {
  display: none !important;
}

.wysiwyg-content {
  min-height: 200px !important;
  max-height: 200px !important;
  font-size: 14px !important;
  overflow-y: scroll;
}

mdb-wysiwyg div textarea#demo {
  display: none !important;
}

div.pe-commitment-modal
  > div.form-check
  > mdb-wysiwyg
  > div
  > div.wysiwyg
  > div.wysiwyg-content {
  min-height: 100px !important;
  max-height: 100px !important;
  overflow-y: auto !important;
  font-size: 14px;
}

.pe-commitment-modal > mdb-wysiwyg > div > div.wysiwyg > div.wysiwyg-content {
  min-height: 100px !important;
  max-height: 100px !important;
  overflow-y: auto !important;
  font-size: 14px;
}

// NG SELECT
.ng-select .ng-select-container {
  background-color: #f5f5f5 !important;
}

//mdb popover

mdb-popover.ng-tns-c4271093901-10 {
  width: 273px;
}

mdb-popover .popover {
  width: 270px;
  padding: 0px;
  // height: 0px;
  height: auto !important;
}

mdb-popover .popover .popover-body {
  max-height: 300px;
  overflow-y: scroll;
}

// Quill
.ql-container {
  font-family: Arial, sans-serif !important;
  font-size: 9pt !important;
  height: 100px; /* Set a fixed height */
}

.ql-size-small {
  font-size: 9pt !important;
}
.ql-size-large {
  font-size: 10pt !important;
}

.ql-snow .ql-editor h1 {
  font-size: 9pt !important;
}

// PE COMMITMENT COVER PHOTO
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  /* border-radius: 8px; */
  overflow: hidden;
  image.image {
    border: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  i.delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
