.env-footer {
    position: absolute;
    bottom:10px;
    right: 10px;
}

.env-text {
    font-weight: bold;
    margin-right: 5px;
    color: $primary;
    text-transform: uppercase;
}

.version-txt {
    font-weight: bold;
    color: $primary;
}