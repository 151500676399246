// .toast {
//   &-info,
//   &-success,
//   &-danger,
//   &-warning,
//   &-primary,
//   &-secondary,
//   &-light {
//     background-image: none !important;
//   }
// }
.toast {
  background-color: none !important;
  
  &-info,
  &-success,
  &-danger,
  &-warning,
  &-primary,
  &-secondary,
  &-light {
    background-image: none !important;
    color: $white !important;
  }

  &-success {
    background-color: $success-color !important;
  }

  &-danger {
    background-color: $danger !important;
  }

  &-warning {
    background-color: $warning !important;
  }

  &-primary {
    background-color: $primary !important;
  }

  &-secondary {
    background-color: $secondary !important;
  }

  &-info {
    background-color: #def1f7 !important;
  }

  &-header {
    border: none !important;
  }
}
