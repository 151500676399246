.modal-header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #dee2e6;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-xl {
  max-width: 1150px !important;
}