app-header {
    &.sticky-top {
        z-index: 1035;
    }
    .navbar{
        height: $navbar-height;
    }
}

.fixed-top {
    z-index: 1036;
}

.nav-tabs .nav-link {
    text-transform: uppercase !important;
    padding: 16px 16px 8px !important;
    font-size: 12px !important;
    &.active {
        color: $secondary !important;
        border-color: $secondary !important;
    }
}

// Profile page 

.custom-tabs{
    mdb-tabs{
        padding: 0;
        
    }
    .nav-tabs {
        background-color: $grey-light;
    }
    .tab-content{
        padding-left: 2rem;
        padding-right: 2rem;
    }
}