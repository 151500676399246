@import 'assets/scss/icofont';
// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

/* You can add global styles to this file, and also import other style files */
@import "assets/scss/_custom-variables.scss";
@import "../node_modules/mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "assets/scss/_custom-styles.scss";
@import '../node_modules/mdb-angular-file-upload/scss/file-upload.scss';
@import '../node_modules/mdb-angular-wysiwyg/scss/wysiwyg.scss';
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';