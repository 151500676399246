// .select-arrow:before {
//   content: url("../../img/svg/feather-chevron-right.svg");
// }

input.form-control.select-input {
  opacity: 1 !important;
  cursor: pointer !important;
}

mdb-select.is-invalid {
  border: 1px solid #d32f2f;
}

mdb-form-control.is-invalid {
  border: 1px solid #d32f2f;
}

.form-control.is-invalid,
.form-control.select-input.is-invalid {
  border-color: #d32f2f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.option.disabled,
mdb-form-control.disabled {
  cursor: not-allowed !important;
}
mdb-select.financing-amount > input.form-control {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
}
