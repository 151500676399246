// @import "node_modules/mdb-angular-ui-kit/assets/scss/bootstrap/bootstrap.scss";
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/colors';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/bootstrap/functions';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/bootstrap/variables';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/variables';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/variables-pro';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/mixins';
// @import 'node_modules/mdb-angular-ui-kit/assets/scss/core/msc/skins-pro';

.avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    vertical-align: top;
  }

  &-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
  }

  &-xl {
    width: 120px;
    height: 120px;
  }

  &-lg {
    width: 70px;
    height: 70px;
    line-height: 65px;
    text-align: center;
    overflow: hidden;
    position: relative;
    // border: 5px solid $light;

    i {
      color: $primary;
      font-size: 1.5rem;
    }
  }
}

.label-size-md {
  label {
    font-size: 12.8px !important;
  }
}

.label-size-md {
  label,
  span.snapshot-table-data {
    font-size: 14px !important;
  }
}

.separator {
  display: inline-block;
  text-align: center;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: $grey-light;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-image {
  height: 5rem;
}

.fa-ul {
  margin-left: 1.25rem !important;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-18px {
  width: 16px;
  margin-right: 8px;
}
.text-lightgreen {
  color: #a9c2ac;
}
.text-sm {
  font-size: 0.75rem !important;
}
.text-xs {
  font-size: 10px !important;
}
.font-sm {
  font-size: 11px;
}

.bg-trans {
  background-color: transparent !important;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: transparent !important;
  }
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      20: 0.2,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
);

.shadow-light {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.04);
}

.y-scroll {
  overflow-y: auto;
  height: calc(100vh - 135px);
}

@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background:
    if(
      $color == $color-contrast-light,
      shade-color($background, $btn-hover-bg-shade-amount),
      tint-color($background, $btn-hover-bg-tint-amount)
    ),
  $hover-border:
    if(
      $color == $color-contrast-light,
      shade-color($border, $btn-hover-border-shade-amount),
      tint-color($border, $btn-hover-border-tint-amount)
    ),
  $hover-color: color-contrast($hover-background),
  $active-background:
    if(
      $color == $color-contrast-light,
      shade-color($background, $btn-active-bg-shade-amount),
      tint-color($background, $btn-active-bg-tint-amount)
    ),
  $active-border:
    if(
      $color == $color-contrast-light,
      shade-color($border, $btn-active-border-shade-amount),
      tint-color($border, $btn-active-border-tint-amount)
    ),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow(
        $btn-box-shadow,
        0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5)
      );
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5);
    }
  }

  // .btn-check:checked + &,
  // .btn-check:active + &,
  // &:active,
  // &.active,
  // .show > &.dropdown-toggle {
  //   color: $active-color;
  //   background-color: $active-background;
  //   // Remove CSS gradients if they're enabled
  //   background-image: if($enable-gradients, none, null);
  //   border-color: $active-border;

  //   &:focus {
  //     @if $enable-shadows {
  //       @include box-shadow(
  //         $btn-active-box-shadow,
  //         0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5)
  //       );
  //     } @else {
  //       // Avoid using mixin so we can pass custom focus shadow properly
  //       box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5);
  //     }
  //   }
  // }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
  }
}

$utilities: (
  "text-decoration": (
    property: text-decoration,
    values: none,
  ),
);

@import "node_modules/mdb-angular-ui-kit/assets/scss/bootstrap/utilities/api";
