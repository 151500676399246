.form-control {
  min-height: 2.5rem !important;
  background-color: $input-bg !important;
  // padding: 0;
  font-size: 0.85rem !important;

  &-sm {
    min-height: 2rem !important;
  }

  &.single,
  &.multiple {
    padding: $input-padding-y $input-padding-x;
    background-color: $input-bg;
    border: 1px solid #ececec;
    border-radius: $input-border-radius;
  }
  &.transparent-input {
    outline: none;
    background: transparent !important;
    border: none;
    padding: 0px 0px 0px 0px !important;

    &:focus {
      border-bottom: 1px solid $input-border-color;
    }

    &:disabled {
      opacity: 1 !important;
    }
  }

  &[readonly],
  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed;
    background-color: $input-bg !important;
  }
}
.default-groupname:disabled {
  opacity: 1 !important;
}

.inline-form-design,
.textarea-lable-view {
  td {
    min-height: 40px;
    padding: 0.125rem 0.25rem !important;
    border: 1px solid $border-blue !important;

    // vertical-align: top !important;
    p {
      padding: 4px;
    }
    &:nth-child(1) {
      width: 200px;
    }

    p {
      color: $title-color;
      font-weight: 500;
    }
  }
  .border-0 {
    border: 0 !important;
  }
}

.inline-form-data {
  display: flex;
  align-items: center;
}

.dropdown-item {
  // padding: 0.85rem 1rem;
  cursor: pointer;
}

.md-form {
  > input[type]:-webkit-autofill:not(.browser-default):not([type="search"])
    + label,
  > input[type="time"]:not(.browser-default) + label {
    transform: translateY(-14px);
    transform-origin: 0 0;
    font-size: 0.8rem;
  }
}

.md-form input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $body-text-color;
  opacity: 1;
  /* Firefox */
}

// mdb-select {
//   background-color: $input-bg;

//   font-size: 0.85rem !important;

//   &.single,
//   &.multiple {
//     padding: $input-padding-y $input-padding-x;
//     background-color: $input-bg;
//     border-radius: $input-border-radius;
//   }
// }

.right-field {
  // margin: .5rem 0;
  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.5rem !important;

    .form-control, >mdb-form-control {
      width: 50%;
    }
  }
}

.input-group {
  .form-control {
    min-height: 2.5rem !important;
    height: 2.5rem !important;
  }

  .input-group-text {
    height: 2.5rem !important;
  }
}

.form-outline {
  .form-control {
    min-height: 2.5rem !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
  }

  // .form-notch {
  //   .form-notch-leading,
  //   .form-notch-middle,
  //   .form-notch-trailing {
  //     border-radius: 2px !important;
  //   }
  // }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-sm {
  .form-control {
    height: 30px;
  }
  input {
    // padding-left: 0.5rem;
  }
  mdb-date-picker {
    .md-form {
      margin-top: 0px !important;

      .form-control {
        height: 30px !important;
        box-sizing: border-box;
      }
    }
  }

  .form-group {
    margin-bottom: 0.35rem;
  }

  .form-control.single,
  .form-control.multiple {
    padding: 0;
    height: 30px !important;
  }
}

.form-group {
  position: relative;
}

.overridden-text {
  font-size: 11px;
}

.right-field {
  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .form-control {
      width: 50%;
    }

    mdb-date-picker {
      width: 50%;
      .md-form {
        .form-control {
          width: 100%;
        }
      }
    }
  }
}

.mdb-font-bold {
  label {
    font-weight: bold;
  }
  mdb-date-picker {
    .md-form {
      .form-control {
        font-weight: bold;
      }
    }
  }
}

// search input
.has-search {
  .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    pointer-events: none;
    color: $grey-lighten-1;
    right: 0;

    + .form-control {
      padding-left: 0.8rem;
    }
  }
}
.form-group-row {
  margin-bottom: 0.35rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}