// .table-responsive{
//     max-height: calc(100vh - 186px);
//     max-width: calc(100vw - 106px);
// }
// .table th {
//     font-weight: 500 !important;
// }

// .table td {
//     font-weight: 400;
//     white-space: nowrap;
// }

// .table.table-hover thead tr th {
//     cursor: pointer;
// }

// .table thead tr {
//     background-color: $grey-lighten-2;
//     border-bottom: 1px solid #ececec;
// }

.table {
  thead {
    tr {
      background-color: $grey-lighten-2;
      // box-shadow: 0px 1px 0px rgba(0,0,0, 0.16);
      border-bottom: 1px solid #ececec;
    }

    th {
      font-weight: 500;
      border-bottom: 0;
      vertical-align: top;
      padding: 8px 8px !important;
      font-size: 0.8rem;
      color: $body-text-color;

      .fas {
        color: gray;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  tr {
    th {
      // padding:12px 8px;
      // border-bottom: 0;
      border-top: 0;
    }
    td {
      font-weight: 400;
      // padding-right: 16px;
      color: $body-text-color;
      vertical-align: middle !important;
      font-size: 0.8rem;
      border-bottom: 1px solid #ececec;
      border-top: 0;
      padding: 4px 8px !important;
      p {
        margin-bottom: 0;
      }
    }
    //
    // &.green {
    //   td {
    //     border-top: 1px solid lighten($primary, 20%);
    //   }
    // }
    &.active {
      background-color: $grey-lighten-2;
    }
  }

  &.table-hover {
    thead tr th {
      cursor: pointer;
    }
    tbody tr {
      &:hover {
        // cursor: pointer;
        background-color: lighten($primary, 50%);
      }
    }
  }
  // data tape table
  .datatape {
    table {
      table-layout: fixed;
      width: 100%;
      border: 0;
    }

    th {
      height: 30px;
      vertical-align: middle !important;
      border-bottom: 0.5px solid $border-grey !important;
    }
    td,
    th {
      border: 0.5px solid $border-grey;
      font-size: 0.8rem;
      font-weight: 500;
      text-align: center;
      padding: 6px !important;
      vertical-align: top !important;
    }
    td {
      // vertical-align: top !important;
      font-weight: 400;
      overflow: hidden;
      box-shadow: 1px 0 1px -1px rgba(85, 82, 82, 0.3);
    }
    tbody th {
      background-color: white;
    }
    tbody tr:nth-of-type(odd) {
      background-color: #f1f0f0;
    }

    .hard_left {
      position: sticky;
      left: 0;
      width: 100px;
      text-align: center;
      background-color: $grey-lighten-3;
      p {
        margin-top: 20px;
      }
    }
    .next_left {
      position: sticky;
      left: 0px;
      width: 320px;
      text-align: left;
      background-color: white;
      border-bottom: 1px solid #e8e6e6;
    }
    .second_left {
      width: 100px;
      position: sticky;
      left: 220px;
    }
    tr:nth-child(odd) {
      .next_left {
        background-color: #f1f0f0;
      }
    }

    .next_left_cashflow {
      position: sticky;
      left: 0px;
      width: 100px;
      top: 80px;
      // text-align: left;
      background-color: $border-blue;
      border: 1px solid $border-grey;
      p {
        margin-top: 20px;
      }

      &:nth-child(2) {
        left: 100px;
      }
      &:nth-child(3) {
        left: 200px;
      }
      &:nth-child(4) {
        left: 300px;
      }
    }
    .th-left-fixed {
      position: sticky;
      left: 0;
      top: 0;
      width: 100px;
      z-index: 2 !important;
      // background-color:$border-blue;
      background-color: #e6e6e6;
      border: 1px solid $border-grey;

      &:nth-child(2) {
        left: 100px;
        width: 300px;
        background-color: $grey-lighten-3;
        border: 1px solid $border-grey;
      }
    }
    .th-left-fixed-at-risk {
      @extend .th-left-fixed;
      width: 320px;
    }
    .th-left-fixed-datatape {
      @extend .th-left-fixed;
      width: 320px;
    }
    .th-left-fixed_2nd {
      position: sticky;
      top: 60px;
      left: 0;
      width: 100px;
      z-index: 89;
      border: 1px solid $border-grey;
      background-color: #e6e6e6;

      &:nth-child(2) {
        left: 100px;
        background-color: $border-blue;
        border: 1px solid $border-grey;
        background-color: $grey-lighten-3;
      }
      &:nth-child(3) {
        left: 200px;
        background-color: $border-blue;
        border: 1px solid $border-grey;
      }
      &:nth-child(4) {
        left: 300px;
        background-color: $border-blue;
        border: 1px solid $border-grey;
      }
    }
    .th-left-fixed-datatape_2nd {
      width: 320px;
      position: sticky;
      left: 320px;
      background-color: $border-blue;
      z-index: 89;
    }
    .next_left_datatape {
      position: sticky;
      left: 0px;
      width: 320px;
      top: 0px;
      background-color: $border-blue;
      border: 1px solid $border-grey;
      p {
        margin-top: 20px;
      }

      &:nth-child(2) {
        left: 100px;
      }
      &:nth-child(3) {
        left: 200px;
      }
      &:nth-child(4) {
        left: 300px;
      }
    }
    .second_left_datatape {
      width: 320px;
      position: sticky;
      left: 320px;
      background-color: $border-blue;
      border: 1px solid $border-grey;
    }

    .v-middle {
      td,
      th {
        vertical-align: middle !important;
      }
    }
  }
  &-wrapper {
    position: relative;
  }
}

.row-sep {
  border-collapse: separate;
  border-spacing: 0 4px;
}
.thead-gray {
  thead tr {
    background-color: #f3f3f3;
    th {
      color: $title-color;
    }
  }
}
.table-amortization {
  // margin-top: .5rem;
  thead {
    th {
      font-weight: 400;
      border-bottom: 0;
      font-size: 0.875rem;
      padding: 0.85rem 0.5rem;
      text-align: center;
    }
  }
  tbody {
    td {
      text-align: center;
    }
  }
}

.th-icon {
  width: 2px;
  vertical-align: middle;
  margin-left: 2px;
  position: relative;
  .fas {
    position: absolute;
  }
  .fa-sort-down {
    top: -7px;
  }
  .fa-sort-up {
    top: -2px;
  }
}
.islock {
  .form-check-input[type="checkbox"] + label,
  label.btn input[type="checkbox"] + label {
    padding-left: 0.5rem !important;
  }
}

//  report table smaller fonts

.report-table {
  tr {
    td {
      font-size: 13px;
      padding: 10px 6px;
    }
    hr {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
.header-tr th {
  padding: 2px;
}

// programs
.detail-table {
  thead tr th {
    cursor: default;
  }
  margin: 0 2px;
  tr td {
    font-size: 0.8rem;
    border: 0;
    padding: 0.125rem 0.5rem !important;
    font-weight: 400;
    vertical-align: middle;
    color: #616161;
    // word-break: break-all;
    .v-top {
      vertical-align: top;
    }

    label {
      margin-bottom: 0;
      font-size: 0.8rem;
      color: $title-color;
    }
    &:nth-child(2) {
      font-weight: 500;
      font-size: 0.8rem;
      color: $title-color;
    }
  }
  // tbody tr {
  //   &:hover {
  //     background-color: inherit !important;
  //   }
  // }
}

.detail-table-simple {
  @extend .detail-table;
  tr td {
    &:nth-child(2) {
      font-weight: 400;
    }
  }
  p {
    padding-left: 10px;
  }
}
.padding-small {
  @extend .detail-table;
  tr td {
    padding: 0.25rem 0.5rem !important;
  }
}

// data tape table
.datatape {
  table {
    table-layout: fixed;
    width: 100%;
    border: 0;
  }

  th {
    height: 30px;
    vertical-align: middle !important;
    border-bottom: 0.5px solid $border-grey !important;
  }
  td,
  th {
    border: 0.5px solid $border-grey;
    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    padding: 6px !important;
    vertical-align: top !important;
  }
  td {
    // vertical-align: top !important;
    font-weight: 400;
    overflow: hidden;
    box-shadow: 1px 0 1px -1px rgba(85, 82, 82, 0.3);
  }
  tbody th {
    background-color: white;
  }
  tbody tr:nth-of-type(odd) {
    background-color: #f1f0f0;
  }

  .hard_left {
    position: sticky;
    left: 0;
    width: 100px;
    text-align: center;
    background-color: $grey-lighten-3;
    p {
      margin-top: 20px;
    }
  }
  .next_left {
    position: sticky;
    left: 0px;
    width: 320px;
    text-align: left;
    background-color: white;
    border-bottom: 1px solid #e8e6e6;
  }
  .second_left {
    width: 100px;
    position: sticky;
    left: 220px;
  }
  tr:nth-child(odd) {
    .next_left {
      background-color: #f1f0f0;
    }
  }

  .next_left_cashflow {
    position: sticky;
    left: 0px;
    width: 100px;
    top: 80px;
    // text-align: left;
    background-color: $border-blue;
    border: 1px solid $border-grey;
    p {
      margin-top: 20px;
    }

    &:nth-child(2) {
      left: 100px;
    }
    &:nth-child(3) {
      left: 200px;
    }
    &:nth-child(4) {
      left: 300px;
    }
  }
  .th-left-fixed {
    position: sticky;
    left: 0;
    top: 0;
    width: 100px;
    z-index: 2 !important;
    // background-color:$border-blue;
    // background-color: #e6e6e6;
    // border: 1px solid $border-grey;

    &:nth-child(2) {
      left: 100px;
      width: 300px;
      background-color: $grey-lighten-3;
      border: 1px solid $border-grey;
    }
  }
  .th-left-fixed-at-risk {
    @extend .th-left-fixed;
    width: 320px;
  }
  .th-left-fixed-datatape {
    @extend .th-left-fixed;
    width: 320px;
  }
  .th-left-fixed_2nd {
    position: sticky;
    top: 60px;
    left: 0;
    width: 100px;
    z-index: 89;
    border: 1px solid $border-grey;
    background-color: #e6e6e6;

    &:nth-child(2) {
      left: 100px;
      background-color: $border-blue;
      border: 1px solid $border-grey;
      background-color: $grey-lighten-3;
    }
    &:nth-child(3) {
      left: 200px;
      background-color: $border-blue;
      border: 1px solid $border-grey;
    }
    &:nth-child(4) {
      left: 300px;
      background-color: $border-blue;
      border: 1px solid $border-grey;
    }
  }
  .th-left-fixed-datatape_2nd {
    width: 320px;
    position: sticky;
    left: 320px;
    background-color: $border-blue;
    z-index: 89;
  }
  .next_left_datatape {
    position: sticky;
    left: 0px;
    width: 320px;
    top: 0px;
    background-color: $border-blue;
    border: 1px solid $border-grey;
    p {
      margin-top: 20px;
    }

    &:nth-child(2) {
      left: 100px;
    }
    &:nth-child(3) {
      left: 200px;
    }
    &:nth-child(4) {
      left: 300px;
    }
  }
  .second_left_datatape {
    width: 320px;
    position: sticky;
    left: 320px;
    background-color: $border-blue;
    border: 1px solid $border-grey;
  }

  .v-middle {
    td,
    th {
      vertical-align: middle !important;
    }
  }
}

// data tape ends

.outer {
  position: relative;
}
.inner {
  overflow-x: scroll;
  overflow-y: visible;
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
}

.data-tape-inner {
  @extend.inner;
  min-height: 75vh;
  max-height: 80vh;
}
.payment-detail-inner {
  @extend .inner;
  min-height: 0 !important;
}
.dashboard-inner {
  overflow-x: scroll;
  overflow-y: visible;
  min-height: calc(100vh - 230px);
  max-height: calc(100vh - 230px);
}
.CF_inner {
  min-height: calc(100vh - 220px);
  max-height: calc(100vh - 220px);
}
.long-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
}

.text-lg {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Client Assessment Payment modal Table
.payment-table {
  margin-bottom: 0;
  tr th {
    padding: 4px 8px;
    border-bottom: 1px solid $grey-lighten-3;
    background-color: white;
    text-align: right;
    font-weight: 600;
    &:last-child {
      padding-left: 20px;
    }
  }
  tr {
    td {
      border: 0px;
      padding: 0.125rem 0.5rem;
      text-align: right;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        padding-left: 20px;
        padding-right: 0.125rem;
      }
      input {
        text-align: right;
      }
    }
  }
  .payment-total td {
    background-color: $blue-light;
    border-top: 1.5px solid $grey-lighten-3;
    background-color: $blue-light;
    font-weight: 500;
    padding: 0.5rem;
    color: $title-color;
    &:last-child {
      padding-right: 12px;
    }
  }
}

.rec-date {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  small {
    width: 104px;
  }
  input {
    width: 180px;
  }
}

.table-sm td {
  height: 30px !important;
}
.fixed-header-datatape {
  background-color: #d0cece;
  position: sticky;
  top: 0 !important;
}
.fixed-header-side-header {
  @extend .fixed-header-datatape;
  z-index: 1 !important;
}

.fixed-header-datatape-second:nth-child(2) {
  @extend .fixed-header-datatape;
  top: 50px !important;
}
// table header fixed
.fixed-header tr th {
  background-color: $grey-lighten-3;
  position: sticky;
  top: 65px;
  // border: 1px solid $border-grey;
}
.fixed-header-tr tr:first-child {
  background-color: $grey-lighten-2;
  position: sticky;
  top: 75px;
  // border: 1px solid $border-grey;
}

.fixed-header-cashflow tr th {
  // background-color: $grey-lighten-2;
  background-color: $border-blue;
  position: sticky;
  top: 0px;
  border: 1px solid $border-grey;
}
.fixed-header-cashflow tr:nth-child(2) th {
  position: sticky;
  top: 40px;
  // background-color:$border-blue;
  // border: 1px solid $border-grey;
}
.fixed-header-dt tr th {
  background-color: #e6e6e6;
  position: sticky;
  top: 0px;
  border: 1px solid $border-grey;
}

// table scroll X and Y
.xy-scroll {
  max-width: 100vw;
  // overflow-x: auto;
  min-height: calc(100vh - 157px);
  max-height: calc(100vh - 157px);
  overflow: auto;
  .fixed-header tr th {
    background-color: $grey-lighten-2;
    position: sticky;
    top: 0 !important;
    z-index: 2;
    // border: 1px solid $border-grey;
  }
  .fixed-header-tr tr:nth-child(2) {
    background-color: $grey-lighten-2;
    position: sticky;
    top: 0px !important;
    // border: 1px solid $border-grey;
  }
}
.striped {
  tr:nth-child(even) {
    background-color: $grey-lighten-2;
  }
}
.xy-scroll_qm {
  max-width: 100vw;
  // overflow-x: auto;
  min-height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  overflow: auto;
}

.table-border {
  tr th,
  td {
    border: 1px solid #e0e0e0;
  }
}
.td-break-md {
  word-break: break-all;
  width: 70%;
}

.coming-soon {
  text-align: center;
  font-size: 70px;
  margin-top: 2rem;
  color: $primary;
}
.print-table {
  td {
    padding: 4px;
    border-bottom: 0 !important;
  }
}
.amo-detail tr td {
  border: 0;
}
.amo-detail tr td:nth-child(2) {
  text-align: right;
}
.table-backlog {
  tr th,
  tr td {
    min-width: 155px;
  }
}
.mdbTooltip {
  z-index: 9999;
}

// align lable on top for text area
.textarea-lable-view {
  tr td {
    vertical-align: top;
  }
}

// dashboard calendar
.picker__box .picker__table {
  td.picker__day {
    padding: 2px !important;
  }
}
.picker__box .picker__header .picker__date-display {
  background-color: $secondary !important;
}
.picker__box .picker__table .picker--focused,
.picker__box .picker__table .picker__day--selected,
.picker__box .picker__table .picker__day--selected:hover {
  background-color: $secondary !important;
}

.td-min-width {
  min-width: 150px !important;
}

.sticky-header {
  position: sticky !important;
  left: 0;
}

.table>:not(caption)>*>* {
  padding: 0;
}

.program-header{
  position: sticky;
  top: 2.25rem;
}
