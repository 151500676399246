//poppins google font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

$white: #ffffff;
$primary: #79bb79;
$primary-color-dark: #4d944e;
$secondary: #3571ba;
$secondary-color-dark: #315585;
$link: #7ba6da;
$blue-light: #e9eff7;
$info: #7ba6da;
$warning: #ffbb33;
$body-text-color: #666;
$title-color: #333;
$success-color: #55aa55;
$light: #e0e0e0;
$grey: #666666;
$grey-light: #ededed;
$grey-lighten-1: #c8c8c8;
$grey-lighten-2: #e1e1e1;
$grey-lighten-3: #f3f3f3;
$grey-lighten-4: #f5f5f5;
$lighten-5: #e3f2fd;
$blue-grey-lighten-5: #eceff1;
$border-blue: #dcebf4;
$border-grey: #dedbdb;
$input-disabled: #f1f5f9;
$danger: #d32f2f;
$title-color: #333;
$light-green: #f1f5f8;
$green-lighten-5: #e8f5e9;
$yellow-lighten-2: #fff176;
$yellow-lighten-4: #fff9c4;
$green-lighten-4: #c8e6c9;

$theme-colors: (
  "white": $white,
  "primary": $primary,
  "primary-color-dark": $primary-color-dark,
  "secondary": $secondary,
  "secondary-color-dark": $secondary-color-dark,
  "grey": $grey,
  "light": $light,
  "grey-lighten-1": $grey-lighten-1,
  "grey-lighten-2": $grey-lighten-2,
  "grey-lighten-3": $grey-lighten-3,
  "grey-lighten-4": $grey-lighten-4,
  "blue-grey-lighten-5": $blue-grey-lighten-5,
  "lighten-5": $lighten-5,
  "green-lighten-5": $green-lighten-5,
  "green-lighten-4": $green-lighten-4,
  "title-color": $title-color,
  "danger": $danger,
  "info": $info,
  "light-green": $light-green,
  "body-text-color": $body-text-color,
  "border-blue": $border-blue,
  "border-grey": $border-grey,
  "warning": $warning,
  "yellow-lighten-2": $yellow-lighten-2,
  "yellow-lighten-4": $yellow-lighten-4,
);

// Create your own map
$custom-colors: (
  "custom-color": #900,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// contrast ratio
$min-contrast-ratio: 2;

//grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1400px,
  xl: 1920px,
);

// spacer
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  //4px
  2: $spacer * 0.5,
  //8px
  3: $spacer * 1,
  //16px
  4: $spacer * 1.5,
  //24px
  5: $spacer * 3 //48px,,,,,,,
);

// Typography

$font-weight-normal: 400;
$font-weight-bold: 500;
$headings-font-weight: $font-weight-bold;
$headings-color: #000000;

$font-weight-base: $font-weight-normal;
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;

// font sizes
$font-sizes: (
  1: $font-size-sm,
  2: $font-size-base,
);

// Header tags
$h1-font-size: $font-size-base * 2.25; //40px
$h2-font-size: $font-size-base * 2; //32px
$h3-font-size: $font-size-base * 1.75; //28px
$h4-font-size: $font-size-base * 1.5; //24px
$h5-font-size: $font-size-base * 1.25; //20px
$h6-font-size: $font-size-base * 1; //16px

// sidenav
$sidenav-width: 250px;
$sidenav-link-font-size: 0.875rem;
$sidenav-transform: translateX(0);
$sidenav-link-border-radius: 0;
$sidenav-background-color: #f3f7fb;

//divider
$breadcrumb-divider: quote(">");

// accordion
$accordion-button-padding-y: 1rem;
$accordion-button-padding-x: 1.25rem;
$accordion-border-radius: 4px;

// dropdown
$dropdown-min-width-md: 15rem;
$dropdown-border-radius: 0.25rem;
// box-shadow
$dropdown-box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
  rgba(9, 30, 66, 0.31) 0px 0px 1px;
// layout container
$grid-gutter-width: 2.5rem;
$container-padding-x: calc($grid-gutter-width / 2);
// $container-max-widths: (
//   xl: 1170px,
//   xxl: 1170px,
// );

$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1440px,
);

// transition
$navbar-scrolling-transition: background 0.2s ease-in-out,
  padding 0.2s ease-in-out !important;

// form

$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-height: 40px;
$form-select-border-color: none;

// navbar
$navbar-height: 65px;
$nav-link-padding-x: 0.625rem;
$zindex-navbar: 10;
$navbar-light-active-color: $white;
$navbar-light-color: rgba(102, 102, 102);
$navbar-padding-top: 0.5rem;
$navbar-padding-bottom: 0.5rem;

//font-weight
$font-weight-bolder: bold;

// input
$input-color: $title-color;
$input-bg: #f5f5f5;
$input-border-color: $blue-grey-lighten-5;
$input-border-radius: 2px;
$input-height: 40px;
$form-group-margin-bottom: 0.625rem;

// switch
$switch-color-bg: $primary;
$switch-color-checked-lever-bg: desaturate(
  lighten($primary, 25%),
  25%
) !default;
$switch-color-unchecked-lever-bg: $input-bg;

// z-index
$zindex-modal-backdrop: 1045;

// card variable
$card-cap-bg: rgba(0, 0, 0, 0.02);
$card-border-color: rgba(0, 0, 0, 0.03);
$card-border-radius: 0.25rem;
$card-box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;

// modal
$modal-md: 600px;
$modal-content-border-radius: 0 !important;

// buttons
$btn-padding-y: 0.65rem;
$btn-padding-y-basic: $btn-padding-y;

// steppers
$stepper-li-a-circle-bg: #fff;
$stepper-horizontal-li-after-bgc: $stepper-li-a-circle-bg;

/*** Global ***/

// Media Query Ranges
$small-screen-up: 601px;
$medium-screen-up: 1401px;
$large-screen-up: 1921px;
$small-screen: 600px;
$medium-screen: 1400px;
$large-screen: 1920px;
$sidenav-breakpoint: 900px;

.avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  overflow: hidden;

  img {
    vertical-align: top;
  }

  &-md {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;
  }

  &-xl {
    width: 120px;
    height: 120px;
  }

  &-lg {
    width: 70px;
    height: 70px;
    line-height: 65px;
    text-align: center;
    overflow: hidden;
    position: relative;
    // border: 5px solid $light;

    i {
      color: $primary;
      font-size: 1.5rem;
    }
  }
}

.separator {
  display: inline-block;
  text-align: center;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: $grey-light;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.bg-image {
  height: 5rem;
}

.fa-ul {
  margin-left: 1.25rem !important;
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      20: 0.2,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
);

.shadow-light {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.04);
}

@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background:
    if(
      $color == $color-contrast-light,
      shade-color($background, $btn-hover-bg-shade-amount),
      tint-color($background, $btn-hover-bg-tint-amount)
    ),
  $hover-border:
    if(
      $color == $color-contrast-light,
      shade-color($border, $btn-hover-border-shade-amount),
      tint-color($border, $btn-hover-border-tint-amount)
    ),
  $hover-color: color-contrast($hover-background),
  $active-background:
    if(
      $color == $color-contrast-light,
      shade-color($background, $btn-active-bg-shade-amount),
      tint-color($background, $btn-active-bg-tint-amount)
    ),
  $active-border:
    if(
      $color == $color-contrast-light,
      shade-color($border, $btn-active-border-shade-amount),
      tint-color($border, $btn-active-border-tint-amount)
    ),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow(
        $btn-box-shadow,
        0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5)
      );
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5);
    }
  }

  // .btn-check:checked + &,
  // .btn-check:active + &,
  // &:active,
  // &.active,
  // .show > &.dropdown-toggle {
  //   color: $active-color;
  //   background-color: $active-background;
  //   // Remove CSS gradients if they're enabled
  //   background-image: if($enable-gradients, none, null);
  //   border-color: $active-border;

  //   &:focus {
  //     @if $enable-shadows {
  //       @include box-shadow(
  //         $btn-active-box-shadow,
  //         0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5)
  //       );
  //     } @else {
  //       // Avoid using mixin so we can pass custom focus shadow properly
  //       box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), 0.5);
  //     }
  //   }
  // }

  &:disabled,
  &.disabled {
    color: $disabled-color;
    background-color: $disabled-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $disabled-border;
  }
}

$utilities: (
  "text-decoration": (
    property: text-decoration,
    values: none,
  ),
);
