.sidenav {
  color: #1e1e1e;
  overflow: visible !important;
  position: fixed;
  left: 0;
  // top: 75px;
  width: 250px;
  z-index: 1040;

  &-item {
    color: $white;
  }
}

.brand {
  width: 100%;
  height: $navbar-height;

  // box-shadow: $navbar-box-shadow;
  &.collapse {
    .logo-full {
      width: 114px; //hide logo when sidenav collapsed
      height: 55px;
      transform: translateX(-200px);
      transition: all ease 0.3s;
    }
    .logo-icon {
      transform: translateX(-126px);
      transition: all ease 0.3s;
    }
  }
  .logo-full {
    width: 114px; //hide logo when sidenav collapsed
    height: 55px;
    transform: translateX(0);
    transition: all ease 0.3s;
  }
  .logo-icon {
    max-width: 1.5rem;
    transform: translateX(-200px); //hide logo when sidenav not collapsed
    transition: all ease 0.3s;
  }
}

.app-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidenav-menu {
  flex: 1;
}

.sidenav-link {
  padding: 1rem 1.5rem;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  display: flex;
  align-items: center;
  color: #555 !important;
  border-right: 3px solid transparent;
  background-color: transparent !important;
  // color: $primary !important;
  text-decoration: none;
  
  i {
  color: #555 !important;
  }

  &:hover {
    .sidenav-primary & {
      background: rgba($primary, 0.1);
    }
  }

  &.active {
    color: $primary !important;
    // @extend .fw-bold;
    border-color: $primary;
    border-right: 5px solid $primary;

    i {
      color: $primary !important;
    }
  }
}

mdb-sidenav-content {
  > div {
    padding-left: 0px;
  }
}
