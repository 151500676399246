.contact-name-header {
  margin: 0;
  align-self: center;
  font-size: 13.8px;
  padding-left: 8px;
  font-weight: 500;
}
.h-40 {
  min-height: 40px !important;
}

.dropdown-menu {
  position: inherit;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}
.popover-content {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(243, 247, 251);
  z-index: 3;
  max-height: 320px;
  min-height: 30px;
  overflow-y: scroll;
  width: 450px;

  > .dropdown-item {
    padding: 0.625rem 1rem;
    text-align: left;
    text-wrap: wrap;
    word-wrap: break-word;
  }
  > .dropdown-item:hover {
    background-color: transparent !important;
  }

  .custom-dropdown:hover & {
    display: block;
    z-index: 999;
  }
}
