.badge {
  font-weight: 400;
  box-shadow: none !important;

  &-default {
    // background-color: $info-color !important;
  }

  &.inprogress {
    // background-color: $warning !important;
  }

  &.completed.badge-default {
    // background-color: $success-color !important;
  }

  &.due {
    background-color: $danger !important;
  }

  &-info {
    background-color: $link !important;
  }
  &-success {
    background-color: $secondary;
  }
  &-link {
    background-color: $link !important;
  }
  &-grey {
    background-color: $grey-lighten-2;
  }
  &-secondary {
    background-color: $secondary !important;
  }
}
.chip {
  margin-right: 0.25rem;
  font-size: 10.8px;
}
