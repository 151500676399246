// contact-project
.bg-light-green {
  background-color: #f1f5f8;
}

.btn-addnotes {
  background-color: $input-bg;
  width: 100%;
  text-align: left;

  i {
    color: white;
    padding: 12px 16px;
    background-color: $secondary;
  }

  span {
    padding-left: 0.85rem;
  }
}
.note-editor {
  iframe {
    height: 200px !important;
  }
}
.notes-scrollbar {
  overflow-y: scroll;
  max-height: 150px;
  padding: 0.5rem 0.25rem;
  margin-top: 0.25rem;
}
.note-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.note-text {
  font-size: 12.8px;
  color: $body-text-color;
  margin-bottom: 0rem;

  p {
    margin-bottom: 0;
  }
}

.note-date {
  font-size: 10px;
  color: lighten($body-text-color, 5%);
  margin-right: 0.5rem;
  margin-bottom: 0rem;
  max-width: 70px;
}

//  project filter dropdown
.project-filter {
  width: 450px;
  padding: 0.5rem 1rem;
}

.program-filter{
  width: 360px;
  padding: 0.5rem 1rem;

}

.contact-list-view {
  padding-left: 0.5rem;

  li {
    list-style: none;
    margin-bottom: 0.25rem;
    font-size: 13px;
  }
}
// project summary pdf
.amo-report-pdf {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 7rem !important;
}

.sweepdate-sec {
  height: 160px;
  overflow-y: auto;
}

.sortable-list {
  overflow-y: auto;
  list-style-type: none;
  padding-left: 0px;
  margin-bottom: 0;
  padding: 8px;

  li {
    // background-color:  $border-blue;
    padding: 8px;
    font-size: 13px;
    color: $body-text-color;
    background-color: #f3f7fb;
    margin-bottom: 4px;

    &:last-child {
      border: 0;
    }
  }
}

.mdb-sortable-placeholder {
  opacity: 1;
  background-color: $primary !important;
  color: #fff !important;
  cursor: resize;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.04);
  transform: translate(-5px, -15px);

  &:first-child {
    margin-top: 8px;
  }
}

.items-card {
  border: 1px solid #3571ba;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.04);
}

.items-list-col {
  overflow-y: auto;

  li {
    font-size: 13px;

    &:focus,
    &:hover {
      cursor: pointer;
      background-color: lighten($primary, 50%) !important;
    }
  }
}

.h-35-vh {
  height: calc(35vh - 40px);
}

.h-35 {
  height: 35vh;
}

.h-15 {
  height: 15vh;
}

.items-list-col {
  overflow-y: auto;

  li {
    font-size: 13px;

    &:focus,
    &:hover {
      cursor: pointer;
      background-color: lighten($primary, 50%) !important;
    }
  }
}

li[mdbdraggable] {
  padding: 8px;
  font-size: 13px;
  color: #666;
  background-color: #f3f7fb;
  margin-bottom: 4px;
  z-index: 1202;
  list-style: none;
}
