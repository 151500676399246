.side-nav {
  // @extend .z-depth-1;

  .slim & {
    width: 3.75rem !important;

    .logo-wrapper {
      button {
        margin-left: 0.625rem;
      }

      a {
        img {
          display: none;
        }

        i {
          display: block;
          margin-left: 0.75rem;
          font-size: 1.25rem;
        }
      }
    }
  }

  .collapsible {
    @extend .bg-transparent;
    .card {
      &.active {
        border: 0 !important;

        mdb-accordion-item-head {
          // border-right: 5px solid $primary;
          @extend .bg-transparent;

          a {
            font-weight: 500;

            h5,
            .far,
            .fas,
            .fab {
              color: $primary !important;
            }
          }
        }
      }

      mdb-accordion-item-head {
        a {
          height: 50px !important;
          line-height: 50px !important;

          h5 {
            line-height: 50px !important;
            font-weight: 400 !important;

            // span {
            //   font-size: .95rem;

            // }
          }

          .far,
          .fas,
          .fab {
            font-size: 1rem !important;
            color: $title-color;
            margin-right: 8px !important;
            display: inline-block;
            width: 20px;
            height: 20px;
          }
        }

        &.active-menu {
          // border-right: 5px solid $primary;
          @extend .bg-transparent;

          a {
            font-weight: 500;

            h5,
            .far,
            .fas,
            .fab,
            i {
              color: $primary !important;
            }
          }
        }
      }
      a {
        height: 40px !important;
        line-height: 40px !important;

        h5 {
          line-height: 50px !important;
          font-weight: 400 !important;

          // span {
          //   font-size: .95rem;

          // }
        }

        .far,
        .fas,
        .fab {
          font-size: 1rem !important;
          color: $title-color;
          margin-right: 8px !important;
          display: inline-block;
          width: 20px;
          height: 20px;
        }
        &.active-menu {
          border-right: 5px solid $primary;
          @extend .bg-transparent;

          // a {
          // font-weight: 500;
          color: $primary !important;
          h5,
          .far,
          .fas,
          .fab,
          i {
            color: $primary !important;
          }
          // }
        }
      }
    }
    .pace-equity-skin .side-nav .collapsible .card-body li a {
      line-height: 50px;
      height: 50px;
      padding-left: 30px;
    }
  }
  .logo-wrapper {
    height: 64px !important;

    a {
      height: 64px !important;
      line-height: 64px;

      img {
        height: 2.34rem;
        padding: 0 10px;

        &:focus {
          outline: none;
        }
      }

      i {
        display: none;
      }
    }
  }
}
// end of side nav

.pace-equity-skin .side-nav .collapsible .card-body li a {
  line-height: 50px;
  height: 50px;
  padding-left: 47px;
  font-weight: 400;
  @extend .bg-transparent;
  &:hover {
    color: $primary !important;
  }
  &:active {
    color: $primary !important;
  }
  &.active-menu {
    color: $primary !important;
    a {
      font-weight: 500;

      h5,
      .far,
      .fas,
      .fab,
      i {
        color: $primary !important;
      }
    }
  }
}
.p-left {
  padding-left: 30px !important;
}
.side-nav .collapsible .fas {
  font-size: 0.8rem;
  margin-right: 2px !important;
}
// @include media-breakpoint-between(sm, md) {

@media (min-width: 768px) and (max-width: 1199px) {
  .side-nav {
    .layoutCollapse & {
      transform: translateX(0) !important;
    }
  }
}

// tab navigation

.tabs-pe {
  // background-color: #7a9f7a !important;
  background-color: #639c63 !important;
  margin: 0 !important;
  position: relative;
  border-radius: 0 !important;
  // @media only screen and (max-width: 1400px) {
  //   &.assessments-tab.classic-tabs.nav {
  //     li:nth-last-child(-n + 4):not(:last-child) {
  //       //last 4 elements : QM,ASM,DSM,Assessments
  //       display: none;
  //     }
  //   }
  // }
  //TODO: Uncomment this if you want assessments to be at the end of tabs
  // &.assessments-tab.classic-tabs.nav {
  //   li {
  //     &:last-child {
  //       // show Assessments tab
  //       display: block;
  //       right: 0;
  //       position: absolute;
  //       margin-right: 10px !important;
  //     }
  //   }
  // }
}

.classic-tabs .nav li a {
  display: block;
  padding: 16px 16px 8px !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: $title-color !important;
  text-align: center;
  border-radius: 0;
  font-weight: 500;
  border-bottom: 3px solid transparent;
}
.classic-tabs .nav li a.active {
  // color: white !important;
  font-weight: 500;
  border-color: $secondary;
}
// subtab
.tabs-light {
  background-color: #fff !important;
}
.sub-tabs > .container-fluid {
  padding: 0 !important;
}
.sub-tabs {
  .nav li a.active {
    color: $secondary !important;
    font-weight: 500;
    border-color: $secondary !important;
  }
  .nav li a {
    display: block;
    padding: 16px 16px 8px !important;
    font-size: 12px !important;
  }
}
@media (min-width: 62em) {
  .sub-tabs {
    .nav li:first-child {
      margin-left: 16px !important;
    }
  }
  .classic-tabs .nav li:first-child {
    margin-left: 8px !important;
  }
}

.side-nav.side-nav-light {
  background-color: #e5e5e5;
  transform: translateX(0%) !important;
}

.side-nav.fixed {
  left: 0;
  position: fixed;
  bottom: 0;
  overflow: hidden;
}
.sidenav-height {
  max-height: calc(100vh - 70px);
  min-height: calc(100vh - 70px);
  overflow: auto;
}
.classic-tabs .nav {
  border-bottom: 1px solid #dee2e6 !important;
}
