.btn {
  border-radius: 2px !important;
  height: 40px;
  // text-transform: unset;
  // border: 1px solid transparent;

  // &:hover,
  // &:disabled,
  // &:visited,
  // &:active,
  // &:focus {
    // color: $white !important;
  // }

  &-secondary {
    background-color: $secondary !important;
    color: $white;
    &:hover,
    &:disabled,
    &:visited,
    &:active,
    &:focus {
      color: $white !important;
      box-shadow: none !important;
      outline: none;
    }
  }
  &-outline {
    box-shadow: none !important;
    outline: none;

    // &:hover,
    // &:disabled,
    // &:visited,
    // &:active,
    // &:focus {
      // color: $white !important;
    //   box-shadow: none !important;
    //   outline: none;
    // }
  }

  &-ellipsis {
    background-color: transparent;
    -webkit-appearance: none;
    border: 0;
    // margin-left: 1rem !important;
    // margin-right: 1rem !important;

    &:hover,
    &:disabled,
    &:visited,
    &:active,
    &:focus {
      // background-color: rgba($black, 0.1);
      color: $secondary !important;
    }
  }

  &-light {
    background-color: $light !important;
    color: #4f4f4f !important;
    &:hover,
    &:disabled,
    &:visited,
    &:active,
    &:focus {
      color: #4f4f4f !important;
    }
  }

  &-link:hover {
    text-decoration: unset !important;
  }

  &-gray {
    background-color: $grey-lighten-3;
  }

  &-print {
    background-color: #7ba6da !important;
    color: white;
  }
  &:disabled {
    opacity: 0.3;
  }
}
.btn[class*="btn-outline-"] {
  padding-top: 0.65rem !important;
  padding-bottom: 1.4rem !important;
}

.btn-lg {
  width: 100%;
}

.fw-btn {
  width: 40px;
  height: 40px;
  padding: 0 !important;
}

button {
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

.menu-toggle {
  z-index: 1041 !important;
  position: fixed !important;
  left: calc(15rem - 20px);
  top: 16px;

  .layoutCollapse & {
    left: calc(3.75rem - 20px);
  }
}

// switch color
// .switch label input[type='checkbox']:checked + .lever:after {
//   background-color: $secondary;
//   left: 1.5rem;
// }

// .switch label input[type='checkbox']:checked + .lever {
//   background-color: lighten($secondary, 20%);
// }

// Medium devices (tablets, 768px and up)

// @include media-breakpoint-between(sm, md) {
@media (max-width: 768px) and (min-width: 1199px) {
  .menu-toggle {
    z-index: 1041 !important;
    position: fixed !important;
    // left: calc(15rem - 20px);
    left: 20px;
    top: 16px;

    .layoutCollapse & {
      left: calc(15rem - 20px);
    }
  }
}

.btn-outline-secondary {
  border: 2px solid $secondary !important;
  color: $secondary !important;
  background-color: white !important;

  &:hover {
    // box-shadow: 1px 3px 6px rgba($black, 0.16) !important;
    border: 2px solid $secondary !important;
    color: $secondary !important;
    background-color: white !important;
  }
}

.box-shadow-none {
  box-shadow: none !important;
  &:hover {
    box-shadow: none;
    transition: none;
  }
}
.btn.btn-link {
  color: black;
  box-shadow: none;
  background-color: transparent;
  outline: 0 !important;
}
.item-float {
  top: 100px;
  right: 41px;
  position: absolute;
}
.btn-edit {
  color: $secondary !important;
  background-color: transparent;
  padding: 0.5rem !important;
  box-shadow: none !important;
  &:hover,
  &:disabled,
  &:visited,
  &:active,
  &:focus {
    color: $secondary !important;
  }
}
.btn-delete {
  color: $danger !important;
  background-color: transparent;
  padding: 0.5rem !important;
  box-shadow: none !important;
  &:hover {
    color: $danger !important;
  }
}

.btn-icon {
  cursor: pointer;

  &_edit {
    &:hover {
      color: $primary;
    }
  }
  .fas {
    font-size: 10px;
  }
}

.btn-sm {
  height: 30px;
  line-height: 1;
  // padding: .35rem 1.6rem;
}
.btn-export {
  border: 1px solid $secondary !important;
  color: $secondary !important;
  &:hover {
    box-shadow: 1px 3px 3px solid rgba(0, 0, 0, 0.16);
  }
}

@media (max-width: 1375px) {
  .btn {
    padding: 0.5rem 1rem !important;
  }
}

.btn-clear {
  background-color: transparent !important;
  border: 1px solid $primary !important;
  color: $primary;
  padding: 0.25rem 0.5rem !important;
  &:hover {
    color: $primary;
  }
}
.btn-underline {
  padding: 4px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  &:hover {
    text-decoration: underline;
    @extend .shadow-light;
  }
}
.hover-danger:hover {
  color: $danger;
}

// switch color
.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: $secondary;
  left: 1.5rem;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: lighten($secondary, 20%);
}

.ecm-item {
  top: 157px;
  right: 38px;
  position: absolute;
}
